$hyoo_realworld_sign_in $mol_page
	title @ \Sign In
	tools /
		<= Close $mol_link
			arg *
				section null
				edit null
			sub / <= Close_icon $mol_icon_close
	body /
		<= Need_account $mol_link
			arg *
				section \sign_up
			sub / <= need_account_label @ \Need an account?
		<= Form $mol_form
			form_fields /
				<= Mail_field $mol_form_field
					name <= Mail_name @ \E-Mail
					control <= Mail $mol_string
						hint \user@example.org
						value?val <=> mail?val \
				<= Pass_field $mol_form_field
					name <= Pass_name @ \Password
					control <= Pass $mol_string
						type \password
						hint \########
						value?val <=> pass?val \
			buttons /
				<= Submit $mol_button_major
					title <= Auth_label @ \Sign In
					click?val <=> auth?val null
