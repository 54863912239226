$hyoo_realworld $mol_book2
	Article!id $hyoo_realworld_article_page
	Feed!id $hyoo_realworld_feed_page
	Article_edit!id $hyoo_realworld_article_edit
	Profile $hyoo_realworld_person_page
		section?name <=> section?name \
	Person $hyoo_realworld_person_page
	sections *
		articles <= feed_current $hyoo_realworld_feed_page
		article <= article_current $hyoo_realworld_article_page
		edit <= edit_current $hyoo_realworld_article_edit
		profile <= Profile
		home <= Home $hyoo_realworld_home_page
		tags <= Tags $hyoo_realworld_tags_page
		sign_in <= Sign_in $hyoo_realworld_sign_in
		sign_up <= Sign_up $hyoo_realworld_sign_up
	plugins /
		<= Theme $mol_theme_auto
