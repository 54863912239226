$hyoo_realworld_article_page $mol_page
	title @ \Article
	tools /
		<= Edit_link $mol_link
			arg * edit \
			sub / <= Edit_icon $mol_icon_edit
		<= Close_link $mol_link
			arg * article null
			sub / <= Close_icon $mol_icon_close
	body /
		<= Content $mol_text
			text <= content \
		<= Tags $mol_row
			sub <= tags /
		<= Comments $mol_list
			rows <= comment_cards /
		<= Comment_add $hyoo_realworld_comment_add
			article <= article $hyoo_realworld_article
	Comment_card!index $hyoo_realworld_comment_card
		comment <= comment!index $hyoo_realworld_comment
	Tag_link!index $mol_link
		arg *
			tag <= tag_name!index \
			article null
		sub / <= tag_name!index \
