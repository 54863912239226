$hyoo_realworld_article_edit $mol_page
	title @ \Article editor
	tools /
		<= Close $mol_link
			arg * edit null
			sub / <= Close_icon $mol_icon_close
	body /
		<= Form $mol_form
			form_fields /
				<= Article_title_field $mol_form_field
					name <= article_title_name @ \Title
					control <= Article_title $mol_string
						value?val <=> article_title?val \
				<= Article_descr_field $mol_form_field
					name <= article_descr_name @ \Description
					control <= Article_descr $mol_textarea
						value?val <=> article_descr?val \
						hint \markdown
				<= Article_content_field $mol_form_field
					name <= article_content_name @ \Content
					control <= Article_content $mol_textarea
						value?val <=> article_content?val \
						hint \markdown
				<= Article_tags_field $mol_form_field
					name <= article_tags_name @ \Tags
					control <= Article_tags $mol_string
						value?val <=> article_tags?val \
						hint \comma, separated, values
			buttons /
				<= Save $mol_button_major
					title <= Save_label @ \Submit
					click?val <=> save?val null
					enabled <= saveable false
