$hyoo_realworld_article_card $mol_row
	minimal_height 72
	article $hyoo_realworld_article
	sub /
		<= Author $mol_link
			arg *
				person <= author_name \
			sub /
				<= Avatar $mol_image uri <= avatar \
				<= Author_name $mol_view sub / <= author_name \
		<= Title $mol_view sub / <= title \
		<= Descr $mol_view sub / <= descr \
