$mol_message $mol_view
	moment $mol_time_moment
	sub /
		<= Info $mol_row
			sub /
				<= Author $mol_link
					uri <= avatar_link \
					sub /
						<= Avatar $mol_image
							title \
							uri <= avatar \
						<= Name $mol_view
							sub /
								<= name \ 
				<= Moment $mol_view
					sub /
						<= moment_string \
		<= Text $mol_text
			text <= text \
